import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import SEO from "../components/seo"
import Layout, { Wide } from "../components/layout"
import { formatDate } from "../utils/date";
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { TwitterShareSmall, SocialLarge, FacebookShareSmall } from "./social"
import { Title, Byline } from "./title"
import Intro from "./intro"
import url from '../utils/url'

const PostMeta = ({post, pageUrl}) => (
  <>
    <Title>{post.frontmatter.title}</Title>
    {post.frontmatter.subtitle && <Byline>{post.frontmatter.subtitle}</Byline>}    
    {post.frontmatter.book_title && <Byline>
      {post.frontmatter.book_title}
      {post.frontmatter.book_author && <> &mdash; {post.frontmatter.book_author}</>}
    </Byline>}
    {post.frontmatter.author && <Byline>{post.frontmatter.author}</Byline>}
    <p className="pb-2 flex items-center">
      <TwitterShareSmall title={post.frontmatter.title} url={pageUrl} />
      <FacebookShareSmall url={pageUrl} />
      <span className="text-gray-500 text-base" >{formatDate(post.fields.date)}</span>
    </p>
  </>
)

const Image = ({post}) => {
  if (post.frontmatter.magazine) {
    return (
      <Link to={`/verhalen/${post.frontmatter.magazine}`} className="max-w-2xl w-full m-auto md:mt-4 mb-4">
        <Img image={post.frontmatter.image.childImageSharp.gatsbyImageData} />
      </Link>
    )
  } else {
    return (
      <div className="max-w-2xl w-full m-auto md:mt-4 mb-4">
        <Img image={post.frontmatter.image.childImageSharp.gatsbyImageData} />
      </div>
    )
  }
}

const WideHeader = ({post, pageUrl}) => <div className="flex w-full -mb-4 flex-col md:flex-col-reverse">
  {!post.frontmatter.no_image && <Image post={post} />}
  <div className="px-4">
    <div className="w-full max-w-xl m-auto pb-2 md:pb-0 md:pt-6">
      <PostMeta post={post} pageUrl={pageUrl} />
    </div>
  </div>
</div>

const BigHeader = ({post, image, pageUrl}) => <div className="md:flex max-h-screen w-screen gap-6">
  <div className="flex-1 self-stretch">
    <Img className="h-full" image={image.childImageSharp.gatsbyImageData} />
  </div>
  <div className="flex-1 self-center">
    <div className="p-4">
      <PostMeta post={post} pageUrl={pageUrl} />
    </div>
  </div>
</div>

const PostHeader = ({post, image, pageUrl}) => {
  if (post.fields.category === `stories` || post.frontmatter.template === `story`) {
    return (<WideHeader post={post} image={image} pageUrl={pageUrl} />);
  }
  if (post.frontmatter.layout === "full_header") {
    return <>
      <div className="md:h-half-screen relative">
        <Img className="md:absolute md:inset-0" image={image.childImageSharp.gatsbyImageData} />
      </div>
      <div className="px-4">
        <div className="w-full max-w-xl m-auto pb-2 md:pb-0 md:pt-6">
          <PostMeta post={post} pageUrl={pageUrl} />
        </div>
      </div>
    </>;
  }
  if (post.frontmatter.layout === "big_header") {
    return (<BigHeader post={post} image={image} pageUrl={pageUrl} />);
  }
  return (
    <div className="px-4">
      <div className="max-w-xl mx-auto">
        <div className="w-full max-w-xl m-auto pb-2 md:pb-0 md:pt-6">
          <PostMeta post={post} pageUrl={pageUrl} />
        </div>
        {!post.frontmatter.no_image && <div className="mx-auto mb-6 w-3/4 md:-mr-12 md:float-right md:w-2/5 md:ml-2">
          <Img image={image.childImageSharp.gatsbyImageData} />
        </div>}
      </div>
    </div>
  );
}

const Author = ({bio, photo}) => {
  return (
    <div className="prose max-w-xl m-auto mt-8">
      <hr />
      {photo && <div className="w-1/4 mr-6 mt-2 float-left">
        <Img image={photo.childImageSharp.gatsbyImageData} className="mt-0 mb-0" />
      </div>}
      <div className="">
        <MDXProvider><MDXRenderer>{bio}</MDXRenderer></MDXProvider>
      </div>
    </div>
  )
}

export default function Post({ data, location }) {
  const post = data.mdx
  const localImages = post.frontmatter.localImages;
  const image = post.frontmatter.cover || post.frontmatter.image
  const pageTitle = post.frontmatter.book_title ? `${post.frontmatter.book_title} – ${post.frontmatter.book_author}` : post.frontmatter.title;
  const pageUrl = url(location, data.site.siteMetadata.siteUrl)
  return (
    <Layout>
      <SEO
        title={pageTitle} 
        description={post.frontmatter.summary || post.excerpt}
        image={image.childImageSharp.gatsbyImageData.images.fallback}
        path={location.pathname}
      />
      <PostHeader post={post} image={image} pageUrl={pageUrl} />
      <Wide>
        {post.frontmatter.intro && <div className="max-w-xl m-auto"><Intro image={post.frontmatter.intro.image} alt={post.frontmatter.intro.alt}>{post.frontmatter.intro.text}</Intro></div>}
        {post.frontmatter.notice && <p className="mb-6 max-w-xl m-auto italic">{post.frontmatter.notice}</p>}
        <div className={`px-4 ${post.frontmatter.no_dropcap ? "" : "dropcap"}`}>
          <MDXProvider>
            <div className="prose max-w-xl prose-lg m-auto">
              <MDXRenderer localImages={localImages}>{post.body}</MDXRenderer>
            </div>
          </MDXProvider>
          {post.frontmatter.author_bio && <Author bio={post.frontmatter.author_bio} photo={post.frontmatter.author_photo} />}
          <div className="max-w-xl m-auto text-gray-600">
            <SocialLarge title={post.frontmatter.title} url={pageUrl}/>
            {/* {post.frontmatter.magazine &&
              <>
              <div className="mt-6">
                <p className="text-2xl">Genoten van dit verhaal?</p>
                Dit verhaal is mogelijk gemaakt door de abonnees van Vonk+. Wil je ook bijdragen een originele Nederlandstalige fantasy- en sciencefiction-verhalen? 
                <p><Link to={`/plus`} className="inline-block mt-4 text-xl bg-red-600 text-white rounded px-5 py-2">
                  Meld je dan hier aan.
                </Link></p>
              </div>
                
                <Link to={`/verhalen/${post.frontmatter.magazine}`} className="inline-block mt-4 text-xl text-red-600">
                  Lees hier alle verhalen van Vonk Magazine
                </Link>
              </>} */}
          </div>
        </div>
      </Wide>
    </Layout>
  );
}

export const query = graphql`query ($slug: String!) {
  mdx(fields: {slug: {eq: $slug}}) {
    body
    fields {
      date
      category
    }
    frontmatter {
      title
      notice
      book_title
      book_author
      author
      summary
      no_image
      subtitle
      template
      layout
      no_dropcap
      magazine
      author_bio
      author_photo {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      localImages {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      intro {
        text
        alt
        image {
          childImageSharp {
            gatsbyImageData(width: 500, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      cover {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    excerpt
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}
`
