import React from 'react'

export const Title = ({Element = 'h1', children}) => <Element className="text-5xl sm:text-6xl font-sans font-bold pb-2">
  {children}
</Element>

export const Heading = ({Element = 'h2', children}) => <Element className="text-4xl font-sans font-bold pb-2">
  {children}
</Element>

export const Byline = ({Element = 'p', children}) => <Element className="text-2xl italic text-gray-500 pb-2">
  {children}
</Element>
