import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Intro = ({children, image, alt}) => {
  return <div className="sm:grid grid-cols-2 gap-6 md:-mx-16 items-center">
  <p className="text-3xl font-light">{children}</p>
  <GatsbyImage className="flex-1 w-full rounded-xl shadow-xl my-8" alt={alt} image={getImage(image)} />
</div>}

export default Intro;
